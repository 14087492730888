/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  background: #fff url(../images/html-bg.jpg) repeat-x top center;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
  background: url(../images/header-bg.jpg) no-repeat center top;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  color: #999999;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  a {
    color: #999999;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
}
.clearfix {
  clear: both;
}
.gsc-search-button-v2 {
  padding: 10px 27px !important;
}
iframe#like {
  max-height: 30px;
}

/* --------- asc styles --------- */
#header {
  a.logo {
    float: left;
    margin-top: 15px;
    margin-bottom: 10px;
    border: 0;
    width: 75%;
    max-width: 528px;
    img {
      width: 100%;
      margin-right: 10px;
    }
  }
  .search-box {
    margin: 30px 0 0 0;
    width: 280px;
    clear: right;
    form {
      height: 30px;
      #input-search {
        font-size: 12px;
        padding: 0 45px 0 5px;
        height: 28px;
        line-height: 30px;
        border: 0;
        color: #555;
      }
      #go {
        width: 38px;
        height: 26px;
        border: 0;
        background: $theme-primary;
        position: absolute;
        right: 1px;
        top: 1px;
        cursor: pointer;
        font-size: 14px;
        line-height: 14px;
        &:hover {
          background: lighten($theme-primary, 7.5%);
        }
      }
    }
  }
}
#notice-wrap {
  border-top: 5px solid #666;
  .notice {
    color: #333;
    background-color: #999;
    font-size: 0.9em;
    a {
      color: #333;
    }
  }
}
#content-wrap {
  background: #fff;
  border-radius: 0 0 3px 3px;
}
.section {
  border: 1px solid #abd2f9;
  h3 {
    background: #3d4153;
    border-radius: 3px 3px 0 0;
    font-size: 14px;
  }
  ul {
    li {
      a:hover {
        color: #333;
        background: #e7e7e7;
      }
      &:before {
        margin-left: 0.5rem;
      }
      a {
        margin-left: 0.5rem;
        padding-right: 10px;
        display: block;
        border: 0;
      }
    }
  }
}

#banner {
  border: 1px solid #09c;
  background: #d5e8f9;
  .banner-content {
    .info {
      max-width: 460px;
    }
  }
  .banner-nav {
    li {
      display: inline-block;
      margin: 5px 3px 0 3px;
      cursor: pointer;
      span {
        padding: 37px 5px 0 5px;
        background: url(../images/icons.png) no-repeat top center;
        font-size: 14px;
        display: block;
        &#resident {
          background-position: 50% -60px;
        }
        &#business {
          background-position: 50% -120px;
        }
        &#visit {
          background-position: 50% -180px;
        }
        &#help {
          background-position: 50% -240px;
        }
        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
      &.active {
        span {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
}
#featured {
  background: #f7f7f7;
  img {
    max-width: 150px;
    border: 1px solid #93afc1;
  }
  .featured-nav {
    background-color: #f7f7f7;
    .item {
      cursor: pointer;
      transition: 0.3s;
      font-size: 0.9em;
      &.active,
      &:hover {
        background: #e1e1e1;
      }
      img {
        width: 45px;
        height: 45px;
        padding: 1px;
      }
    }
  }
}

#info-locator {
  background: #f8f4e1;
  h3 {
    color: #069;
    span {
      font-size: 0.6em;
      color: #555;
    }
  }
  .overflow-wrap {
    max-height: 450px;
    overflow: hidden;
  }
  .viewed {
    max-height: 450px;
    overflow: hidden;
    overflow-y: auto;
  }
  ul.info-nav {
    background: #e9e9e9;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    li a {
      cursor: pointer;
      color: $link-color;
      border: 1px solid #ddd;
      border-radius: 3px;
      background: #ededed;
      &.active,
      &:hover {
        background: #fff;
        border: 1px solid #ddd;
      }
    }
  }
}
#inner {
  h1 {
    text-align: center;
  }
  h2 {
    color: #664;
  }
  h3 {
    color: #8b0000;
  }
  .cta-btn {
    max-width: 260px;
  }
}
.grey-box {
  border: 2px solid #ccc;
}
.red {
  color: #8b0000;
}
#record-table {
  border: 1px solid #999;
  border-top: 5px solid #999;
  .record,
  .pricing {
    font-size: 24px;
    font-weight: bold;
  }
  .record,
  .pricing,
  .order,
  .link {
    width: 100%;
  }

  .line {
    border-bottom: 1px dotted #66ccff;
    font-size: 0;

    &:first-child {
      border-top: 1px dotted #66ccff;
    }
    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background: #e5f6ff;
    }
  }

  .link,
  .rates {
    font-size: 14px;
  }

  .link {
    cursor: pointer;
  }

  .record a {
    color: #333;
  }
}
#pitch {
  border: 1px solid #92aec1;
  max-width: 600px;
  background: rgba(255, 255, 255, 0.85);
  max-width: 650px;

  ul.check {
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    text-align: left;
    max-width: 430px;
    float: none;
    margin: 0;
  }
}
.package {
  border-color: #86bae7;

  p {
    color: #86bae7;
    font-size: 0.9em;
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #header {
    a.logo {
      padding-left: 20px;
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }
  #banner {
    .banner-content {
      border: 3px solid #fff;
      min-height: 250px;
      background: #d5e8f9 url(../images/banner-sm.jpg) no-repeat top right;
    }
    .banner-nav {
      position: absolute;
      right: 30px;
      bottom: 10px;
    }
  }
  #info-locator h3 span {
    top: 8px;
  }
  #record-table {
    .pricing,
    .link,
    .rates,
    .order {
      width: 33.3333333%;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #content-wrap {
    border: 1px solid #92aec1;
  }
  #record-table {
    .record {
      width: 40%;
    }
    .pricing {
      width: 20%;
    }
    .order {
      margin-left: 20% !important;
      width: 20%;
    }
    .link {
      margin-left: -40% !important;
      width: 20%;
    }
    .rates {
      width: 20%;
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #header a.logo {
    width: 50%;
    span.small {
      font-size: 18px;
    }
  }
  #header-wrap {
    #header #support {
      span.phone {
        display: inline-block;
      }
    }
  }
  #banner {
    .banner-content {
      background: #ddedfd url(../images/banner-sm.jpg) no-repeat top left;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 50%;
    span.small {
      font-size: 18px;
    }
  }
  #header #support {
    display: block;
    span.phone {
      display: inline-block;
    }
  }
  #content-wrap {
    background: #f1f1f1;
  }
  #inner {
    border: 1px solid #abd2f9;
    border-radius: 0.25rem;
  }
  #record-table .record {
    width: 38%;
  }
  #pitch {
    max-width: 775px;
    ul.check {
      max-width: 550px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #header {
    a.logo {
      padding-left: 0;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  #banner {
    .banner-content {
      background: #abd2f9 url(../images/banner.jpg) no-repeat top left;
      .info {
        max-width: 550px;
      }
    }
    .banner-nav {
      li {
        margin: 5px 13px 0 13px;
      }
    }
  }
  #record-table .record {
    width: 40%;
  }
}
